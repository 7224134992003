import React from 'react'
import { Link } from 'react-router-dom'
import './Logo.css'
import LogoImage from '../../assets/images/logo.png'

const Logo = () => (
  <Link to="/" className="logo_link">
    <img className="logo" src={LogoImage} alt="Oracle digital" />
  </Link>
)

export default Logo
