import React from "react";
import "./Card.css";

const Card = ({ item }) => (
  <div className="card">
    <div className="card_img">
      <img src={item?.icon} alt={item?.name} />
    </div>
    <h5>{item?.name}</h5>
    <div
      className="card_description"
      dangerouslySetInnerHTML={{ __html: item?.description }}
    />
    {item?.price && (
      <p className="card_price">
        <span className="card_price_first">от</span>
        <span className="card_price_second">{item?.price}</span>
        <span className="card_price_third">usd</span>
      </p>
    )}
  </div>
);

export default Card;
