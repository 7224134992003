import React from "react";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import SectionSubtitle from "../../../components/UI/SectionSubtitle/SectionSubtitle";
import Card from "../../../components/Card/Card";
import "./OurTarget.css";

import promotion from "../../../assets/icons/promotion.png";
import group from "../../../assets/icons/group.png";
import figur from "../../../assets/icons/figur.png";

const data = [
  {
    name: "Предложение",
    icon: promotion,
    description:
      "Мы готовы предложить вам лучшее решение для вашего бизнеса: чистый код, вкусный дизайн и неповторимый стиль",
  },
  {
    name: "Важный факт",
    icon: group,
    description: "Клиентоориентированность - это важный факт нашей работы",
  },
  {
    name: "Поддержка",
    icon: figur,
    description:
      "Поддержка после окончания разработки, мы не “бросаем” наших клиентов, а предоставляем месяц бесплатной поддержки сайта",
  },
];

const OurTarget = () => (
  <div className="container our_target_con">
    <SectionTitle title="Наша цель" />
    <div className="target_subtitle_block">
      <SectionSubtitle subtitle="Наша главная цель- создание по настоящему хороших проектов, отвечающих требованиям заказчика" />
    </div>
    <div className="our_target_cards">
      {data?.map((item) => (
        <Card key={item.title} item={item} />
      ))}
    </div>
  </div>
);

export default OurTarget;
