import React from "react";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import SectionSubtitle from "../../../components/UI/SectionSubtitle/SectionSubtitle";
import "./CustomerReviews.css";

import star from "../../../assets/icons/star.png";
import caw from "../../../assets/icons/cow.png";
import margo from "../../../assets/images/margo.jpg";
import { useQuery } from "react-query";
import { fetchsReviews } from "../../../api/api";

const reviews = {
  name: "Марго Самойлова",
  message:
    "Сотрудничество с вашей компанией было приятным опытом. Веб-сайт, который вы разработали для нас, отличается отличным дизайном, интуитивно понятным интерфейсом и высокой производительностью. Ваша команда профессионалов действительно талантлива в своем деле. Также вы были готовы предоставить поддержку и вносить изменения в соответствии с нашими потребностями",
  date: "29.11.2022г",
  rate: 5,
  image: margo,
};

const CustomerReviews = () => {
  const { data, isLoading, error } = useQuery("reviews", fetchsReviews);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString("ru-RU", options);
  };
  return (
    <div className="container">
      <div className="reviews_con">
        <SectionTitle title="Что говорят наши клиенты" />
        {data?.length > 0 ? (
          <div className="reviews_block">
            {data?.slice(0, 1).map((reviews) => {
              const stars = [];

              for (let i = reviews?.rating; i !== 0; i -= 1) {
                stars.push(<img key={`star${i}`} src={star} alt="star" />);
              }
              return (
                <div className="reviews_text">
                  <p>
                    <img className="reviews_caw" src={caw} alt="caw" />
                  </p>
                  <p className="reviews_message">{reviews?.message}</p>
                  <p>
                    <span className="reviews_name">
                      {reviews?.first_name} {reviews?.last_name}
                    </span>
                    <span className="reviews_date">
                      {formatDate(reviews?.created_at)} г.
                    </span>
                  </p>
                  <p className="reviews_stars">{stars && stars}</p>
                  <div className="reviews_image">
                    <img src={reviews?.avatar} alt="margo" />
                    <div className="reviews_image_back" />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h4>Пока нет отзывов</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerReviews;
