import React from 'react'
import SectionTitle from '../../../components/UI/SectionTitle/SectionTitle'
import SectionSubtitle from '../../../components/UI/SectionSubtitle/SectionSubtitle'
import AdditionalCard from './AdditionalCard/AdditionalCard'
import './Additional.css'

import first from '../../../assets/images/first.png'
import second from '../../../assets/images/mail-promotion.png'
import third from '../../../assets/images/layer_3.png'
import topImage from '../../../assets/images/top_image.jpg'
import bottomImage from '../../../assets/images/bottom_image.jpg'

const data = [
  { title: 'Веб аналитика', image: first },
  { title: 'Контекстная реклама', image: second },
  { title: 'SMM системы', image: third },
]

const Additional = () => (
  <div className="about_last_section">
    <div className="container">
      <div className="additional_top">
        <div className="additional_title">
          <SectionTitle title="Продолжаем начатое" />
          <SectionSubtitle subtitle="Наша компания также предоставляет полный спектр услуг по продвижению готовых сайтов" />
        </div>
        <div className="additional_cards">
          {data?.map(item => (
            <AdditionalCard key={item.title} item={item} />
          ))}
        </div>
      </div>
      <div className="additional_bottom">
        <div className="additional_bottom_description">
          <SectionTitle title="ORACLE DIGITAL -Это люди" />
          <p>
            Наши профессионалы, которые безукоризненно делает свою работу, ежедневно совершенствуются и увеличивают опыт
            в разработке сайтов.
          </p>
          <p>Мы бесконечно верим в нашу команду, без работы которой невозможно представить успешный проект.</p>
        </div>
        <div className="additional_bottom_images">
          <img className="additional_bottom_image" src={bottomImage} alt="Oracle" />
          <img className="additional_top_image" src={topImage} alt="Oracle" />
        </div>
      </div>
    </div>
  </div>
)

export default Additional
