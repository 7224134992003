import React, { memo, useEffect } from 'react'
import Promo from './Promo/Promo'
import Statics from '../../components/Statics/Statics'
import ProjectsBlock from './ProjectsBlock/ProjectsBlock'
import Feedback from '../../components/Feedback/Feedback'

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Promo />
      <Statics />
      <ProjectsBlock />
      <Feedback />
    </>
  )
}

export default memo(Projects);
