import React from "react";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import SectionSubtitle from "../../../components/UI/SectionSubtitle/SectionSubtitle";
import Block from "./Block/Block";
import "./Working.css";

const data = [
  {
    number: "01",
    title: "Вы можете быть лучше",
    description:
      "Планирование:Определение целей, целевой аудитории, функциональности и структуры сайта.",
  },
  {
    number: "02",
    title: "Первое,второе, третье",
    description:
      "Дизайн: Создание макетов и дизайна, учитывающих пользовательский опыт (UX) и интерфейс (UI).",
  },
  {
    number: "03",
    title: "Покажем визуально",
    description:
      " Разработка:Написание кода, реализация функциональности, работа с базой данных, адаптация под различные устройства.",
  },
];

const data2 = [
  {
    number: "04",
    title: "Искусство дизайна",
    description:
      "Тестирование:Проверка сайта на ошибки, тестирование функциональности, совместимость с разными браузерами и устройствами.",
  },
  {
    number: "05",
    title: "Это можно покрутить",
    description:
      "Релиз:Публикация сайта на сервере, чтобы он стал доступен в интернете.",
  },
  {
    number: "06",
    title: "А это можно занимать",
    description:
      "Техническая поддержка: Бесплатная техническая поддержка в течение 6 месяцев с момента сдачи проекта.",
  },
];

const Working = () => (
  <div className="working">
    <div className="container">
      <SectionTitle title="Как мы работаем" />
      <SectionSubtitle subtitle="Этапы реализации проекта" />
      <div className="working_blocks">
        <div className="blocks_column">
          {data?.map((item) => (
            <Block key={item.number} last={item.number === "03"} item={item} />
          ))}
        </div>
        <div className="blocks_column">
          {data2?.map((item) => (
            <Block key={item.number} last={item.number === "06"} item={item} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Working;
