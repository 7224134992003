import React, { memo, useEffect } from "react";
import Promo from "./Promo/Promo";
import ContactsBlock from "./ContactsBlock/ContactsBlock";
import Feedback from "../../components/Feedback/Feedback";

const Contacts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Promo />
      <ContactsBlock />
      <Feedback>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2923.447871505634!2d74.5838431867181!3d42.88449652743074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389ec81c5f803c2d%3A0x49e7aaeeca5150b3!2zMSwgNjQg0YPQuy4g0JzQsNC90LDRgdCwLCDQkdC40YjQutC10Lo!5e0!3m2!1sru!2skg!4v1715774062341!5m2!1sru!2skg" width="560" height="670" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" frameborder="0"></iframe>

      </Feedback>
    </>
  );
};

export default memo(Contacts);

