import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import theme from "./theme";
import "./index.css";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

const app = (
  <QueryClientProvider client={new QueryClient()}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(app);
