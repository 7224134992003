import React, { useState } from 'react'
import './QuestionBlock.css'

const QuestionBlock = ({ question }) => {
  const [state, setState] = useState(false)

  return (
    <div className="question_block">
      <p className="question" onClick={() => setState(!state)}>
        <span className={state ? 'minus' : 'plus'} />
        <span>{question.question}</span>
      </p>
      {state ? <p className="answer">{question.answer}</p> : null}
    </div>
  )
}

export default QuestionBlock
