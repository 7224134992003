import React, { useState } from 'react'
import './Catalog.css'

const names = ['Мобильные приложения', 'Веб сайты', 'Landing  Page', 'CRM системы', 'Веб ресурсы', 'Интернет магазины']

const Catalog = () => {
  const [state, setState] = useState(0)

  const onClick = i => {
    setState(i)
  }

  return (
    <div className="catalog">
      {names.map((name, i) => (
        <button
          key={name}
          className={`btn_catalog${state === i ? ' catalog_active' : ''}`}
          type="button"
          onClick={() => onClick(i)}
        >
          {name}
        </button>
      ))}
    </div>
  )
}

export default Catalog
