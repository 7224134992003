import React from "react";
import SectionTitle from "../UI/SectionTitle/SectionTitle";
import SectionSubtitle from "../UI/SectionSubtitle/SectionSubtitle";
import FeedbackForm from "./FeedbackForm/FeedbackForm";
import "./Feedback.css";

import feedbackImage from "../../assets/images/feedback_image.jpg";

const Feedback = ({ children }) => (
  <div className="container">
    <div className="feedback_title">
      <SectionTitle title="Создадим идеальный сайт вместе" />
      <SectionSubtitle
        subtitle="Наши профессионалы, которые безукоризненно делает свою работу,
       ежедневно совершенствуются и увеличивают опыт в разработке сайтов."
      />
    </div>
    <div className="feedback">
      <div className={`${children ? "feedback_map" : "feedback_left"}`}>
        {children || (
          <img className="feedback_image" src={feedbackImage} alt="Oracle" />
        )}
      </div>
      <div className="feedback_right">
        <FeedbackForm contact={!!children} />
      </div>
    </div>
  </div>
);

export default Feedback;
