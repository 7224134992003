import React from "react";
import "./CooperateBlock.css";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import SectionSubtitle from "../../../components/UI/SectionSubtitle/SectionSubtitle";
import { useQuery } from "react-query";
import { fetchsPartners } from "../../../api/api";
import { Box, CircularProgress } from "@mui/material";
import { NavLink } from 'react-router-dom';

const CooperateBlock = () => {
  const { data, isLoading, error } = useQuery("partners", fetchsPartners);

  console.log(data);
  return (
    <>
      <div className="container cooperate_con">
        <div className="cooperate_description">
          <div className="year_block">
            <p>С 2018- 2024</p>
          </div>
          <div className="text_block">
            <SectionTitle title="С нами сотрудничают" />
            <SectionSubtitle subtitle="Ваш вклад, наши технологии: сотрудничество, наполненное инновациями." />
            <p>
              Мы гордимся нашими партнерами, которые разделяют нашу страсть к
              технологиям. Совместно мы создаем технологические шедевры,
              приводим в жизнь новые идеи и строим успешные проекты.
            </p>
          </div>
        </div>
      </div>
      <div className="cooperate_icons">
        <div className="container">
          <div className="cooperate_icons_min">
            {isLoading ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {data.length > 0 ? (
                  <div className="cooperate_icons_row">
                    {data?.map((item) => (
                      <div key={item?.id}>
                        <NavLink className="cooperate_icon" target={item?.url ? "_blank" : ""} to={item?.url || "/"}>
                          <img src={item?.logo} alt="oracle" />
                        </NavLink>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    <h4>пусто</h4>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CooperateBlock;
