import React from "react";
import "./CardProject.css";
import Button from "../UI/Button/Button";
import { useState } from "react";
import CardModal from "../CardModal/CardModal";

const CardProject = ({ item }) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      {modal && <CardModal setModal={setModal} modal={modal} item={item} />}
      <div className="card_project">
        <div className="card_project_image">
          <img src={item?.default_image} alt="Oracle" />
        </div>
        <div className="card_project_description">
          <h5>{item?.name.slice(0, 20)}...</h5>
          <div dangerouslySetInnerHTML={{
            __html: item?.description?.slice(0, 100)
          }} />
          <div onClick={() => setModal(!modal)}>
            <Button title="Смотреть приложение" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardProject;
