import React from 'react'
import NavigationItem from './NavigationItem/NavigationItem'
import './NavigationBlock.css'

const items = [
  { name: 'Главная', to: '/' },
  { name: 'О компании', to: '/about' },
  { name: 'Услуги и цены', to: '/services_prices' },
  { name: 'Проекты', to: '/projects' },
  { name: 'Контакты', to: '/contacts' },
]

const NavigationBlock = ({ active, actionMenu, color }) => (
  <nav className={`nav ${active ? 'nav_active' : ''}`}>
    <ul>
      {items.map(item => (
        <li key={item.name}>
          <NavigationItem to={item.to} name={item.name} actionMenu={actionMenu} color={color} />
        </li>
      ))}
    </ul>
  </nav>
)

export default NavigationBlock
