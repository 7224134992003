import React from 'react'
import './AdditionalCard.css'

const AdditionalCard = ({ item }) => (
  <div className="additional_card">
    <div className="additional_card_image">
      <div>
        <img src={item.image} alt="Oralce" />
      </div>
    </div>
    <p>{item.title}</p>
  </div>
)

export default AdditionalCard
