import React, { memo, useEffect } from 'react'
import Promo from './Promo/Promo'
import PricesBlock from './PricesBlock/PricesBlock'
import Feedback from '../../components/Feedback/Feedback'

const Prices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Promo />
      <PricesBlock />
      <Feedback />
    </>
  )
}

export default memo(Prices)
