import React from "react";
import "./Statics.css";

const Statics = ({ margin }) => (
  <div className={`statics${margin ? " margin" : ""}`}>
    <div className="container statics_con">
      <div>
        <span className="number">80+</span>
        <span className="statics_text">ЗАВЕРШЕННЫХ ПРОЕКТОВ</span>
      </div>
      <div>
        <span className="number">+70</span>
        <span className="statics_text">УДОВЛЕТВОРЕННЫХ КЛИЕНТОВ</span>
      </div>
      <div>
        <span className="number">+25</span>
        <span className="statics_text">ПАРТНЕРОВ ПО ВСЕМУ МИРУ</span>
      </div>
    </div>
  </div>
);

export default Statics;
