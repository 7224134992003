import React from "react";
import "./Promo.css";

import promo1 from "../../../assets/images/main_promo.png";
import promo2 from "../../../assets/images/main_promo2.png";

const Promo = () => (
  <div className="main_promo">
    <div className="container">
      <h1>Преобразуем ваши идеи в цифровую реальность!</h1>
      <p>
        Мы - команда экспертов по разработке веб-сайтов и мобильных приложений.
        Наш подход - индивидуальное погружение в ваш бизнес, чтобы создать не
        просто продукт, а инструмент, который ускорит ваш рост. Создаем
        современные и стильные решения, где каждый элемент несет ценность.
        Доверьтесь нам для того, чтобы выделиться в цифровом пространстве и
        привлечь внимание. Вместе мы создадим не просто веб-проект, а мощный
        инструмент вашего успеха.
      </p>
      <a href={`tel:${+996557978715}`} className="link_to_contacts">
        Связаться с нами
      </a>
      <a className="link_section" href="#second">
        {" "}
      </a>
    </div>
  </div>
);

export default Promo;
