import React, { useState } from 'react'
import NavigationBlock from './NavigationBlock/NavigationBlock'
import './Header.css'
import LogoImage from '../../assets/images/logo.png'
import { NavLink } from 'react-router-dom';
import { HiMenu } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Logo from '../Logo/Logo';


const Header = () => {
  const [active, setActive] = useState(false)

  const actionMenu = () => {
    setActive(!active)
    document.body.style.overflowY = active ? 'auto' : 'hidden'
  }

  return (
    <header className="header">
      <div className="container header_con">
        <div className="header_in">
          <div className={`page ${active ? 'page_active' : null}`}>
            <div className={`menu ${active ? 'menu_active' : null}`}>
              <div>
                <NavigationBlock active={active} actionMenu={actionMenu} />
              </div>
            </div>
          </div>
          <div className="logo_block">
            <Logo />
            <span>Разработка сайтов и мобильных приложений</span>
          </div>
          <div className="nav_block">
            <NavigationBlock />
          </div>
          <div className={'block xl:hidden'} onClick={actionMenu}>
            {active ? <AiOutlineCloseCircle className="fill-white w-[42px] h-[42px] cursor-pointer" /> : <HiMenu className="fill-white w-[42px] h-[42px] cursor-pointer" />
            }
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
