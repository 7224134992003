import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: ['Gilroy', 'sans-serif'],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 520,
      md: 720,
      lg: 960,
      xl: 1366,
    },
  },
})

export default theme
