import React, { useEffect, useState } from "react";
import Promo from "./Promo/Promo";
import SecondBlock from "./SecondBlock/SecondBlock";
import CooperateBlock from "./CooperateBlock/CooperateBlock";
import Technologies from "./Technologies/Technologies";
import OurProjects from "./OurProjects/OurProjects";
import Statics from "../../components/Statics/Statics";
import CustomerReviews from "./CustomerReviews/CustomerReviews";
import Questions from "./Questions/Questions";
import { BsFillCapslockFill } from "react-icons/bs";
import { memo } from "react";

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <>

      <Promo />
      <SecondBlock />
      <CooperateBlock />
      <Technologies />
      <OurProjects />
      <Statics margin />
      <CustomerReviews />
      <Questions />
    </>
  );
};

export default memo(Main);
