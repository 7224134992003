import React from "react";
import "./CardModal.css";
import { NavLink } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import play from "../../assets/images/google-play.svg";
import appstore from "../../assets/images/app-store.svg";

export default function CardModal({ setModal, modal, item }) {
  return (
    <div className="card_modal">
      <div className="card_wrapper">
        <div className="card_left">
          <button className="card_close">
            <IoMdClose className="icon" onClick={() => setModal(!modal)} />
          </button>
          <img src={item?.background_image} alt="" />
        </div>
        <div className="card_rigth">
          <IoMdClose onClick={() => setModal(!modal)} className="card_btn" />
          <h3>{item?.name}</h3>
          <div className="card_link">
            {item?.play_store_url && (
              <NavLink
                target="_blank"
                to={item?.play_store_ur}
                className="play"
              >
                <img src={play} alt="" />
              </NavLink>
            )}
            {item?.app_store_url && (
              <NavLink
                target="_blank"
                to={item?.app_store_url}
                className="appstore"
              >
                <img src={appstore} alt="" />
              </NavLink>
            )}
          </div>

          {item?.site_url && (
            <NavLink target="_blank" to={item?.site_url}>
              Смотреть сайт
            </NavLink>
          )}
          <div dangerouslySetInnerHTML={{ __html: item?.description }} />
        </div>
      </div>
    </div>
  );
}
