import React from 'react'
import SectionTitle from '../../../components/UI/SectionTitle/SectionTitle'
import SectionSubtitle from '../../../components/UI/SectionSubtitle/SectionSubtitle'
import './Technologies.css'

import image1 from '../../../assets/icons/JavaScript.png'
import image2 from '../../../assets/icons/html.png'
import image3 from '../../../assets/icons/css.png'
import image4 from '../../../assets/icons/Figma.png'
import image5 from '../../../assets/icons/Miro.png'
import image6 from '../../../assets/icons/Trello.png'
import image7 from '../../../assets/icons/Blender.png'
import image8 from '../../../assets/icons/Visual Studio.png'
import image9 from '../../../assets/icons/C++.png'
import image10 from '../../../assets/icons/C#.png'
import image11 from '../../../assets/icons/C++2.png'
import image12 from '../../../assets/icons/C#2.png'
import image13 from '../../../assets/icons/React.png'
import image14 from '../../../assets/icons/Go.png'
import image15 from '../../../assets/icons/Ruby.png'
import image16 from '../../../assets/icons/Php.png'
import image17 from '../../../assets/icons/Java.png'
import image18 from '../../../assets/icons/Python.png'
import image19 from '../../../assets/icons/Simlime Text.png'
import image20 from '../../../assets/icons/Google Docs.png'
import image21 from '../../../assets/icons/NodeJs.png'
import image22 from '../../../assets/icons/Git.png'
import image23 from '../../../assets/icons/Linux.png'
import image24 from '../../../assets/icons/Apple.png'

const images1 = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12]

const images2 = [
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
]

const Technologies = () => (
  <div className="container">
    <div className="techno_title_block">
      <SectionTitle title="Технологии, которые мы используем" />
      <div className="techno_icons">
        <div className="techno_icons_row">
          {images1?.map((image, i) => (
            <div key={`4image${i}`}>
              <img src={image} alt="Oracle" />
            </div>
          ))}
        </div>
        <div className="techno_icons_row">
          {images2?.map((image, i) => (
            <div key={`3image${i}`}>
              <img src={image} alt="Oracle" />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default Technologies
