import React from 'react'
import './Button.css'

const Button = ({ title }) => (
  <button className="button" type="button">
    {title}
  </button>
)

export default Button
