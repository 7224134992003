import React from "react";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import SectionSubtitle from "../../../components/UI/SectionSubtitle/SectionSubtitle";
import QuestionBlock from "./QuestionBlock/QuestionBlock";
import "./Questions.css";
import QuestionForm from "./QuestionForm/QuestionForm";

const questions = [
  {
    question:
      "Какой опыт у вашей компании в разработке веб-сайтов и мобильных приложений?",
    answer:
      "Наша компания успешно работает в сфере разработки с 2018 года, а члены команды разработчиков имеют опыт более 8 лет",
  },
  {
    question: "Какой срок занимает разработка проекта?",
    answer:
      "Время разработки зависит от сложности проекта. Мы подходим к каждому проекту индивидуально, стремясь к эффективной и быстрой реализации.",
  },
  {
    question: "Как я могу узнать стоимость разработки своего проекта?",
    answer:
      "Чтобы узнать стоимость разработки вашего проекта, свяжитесь с нами, предоставив подробности вашего запроса, и мы составим для вас индивидуальное предложение.",
  },
  {
    question: "Предоставляете ли вы поддержку после завершения проекта?",
    answer:
      "Да, мы предоставляем бесплатную техническую поддержку после завершения проекта в течение 6 месяцев",
  },
  {
    question: "Какой процесс разработки вы предлагаете своим клиентам?",
    answer:
      "Наш процесс включает в себя этапы планирования, дизайна, разработки, тестирования и внедрения. Мы работаем в тесном взаимодействии с клиентами, чтобы обеспечить полное соответствие проекта их ожиданиям.",
  },
  {
    question: "Каковы ваши возможности в области дизайна?",
    answer:
      "Мы предлагаем уникальные и современные дизайны, учитывающие потребности и брендовую идентичность клиента. Наша команда дизайнеров создаст привлекательный и функциональный интерфейс.",
  },
  {
    question: "Какие типы сайтов и приложений вы разрабатываете?",
    answer:
      "Мы специализируемся на разработке корпоративных сайтов, интернет-магазинов, веб-приложений, мобильных приложений под iOS и Android, а также индивидуальных веб-решений.",
  },
  {
    question:
      "Какие технологии вы используете при разработке сайтов и приложений?",
    answer:
      "Мы работаем с современными технологиями, включая HTML5, CSS3, JavaScript, React, Angular, Vue.js для веб-разработки, и Kotlin, Swift, React Native для мобильных приложений",
  },
];

const Questions = () => (
  <div className="questions_block">
    <div className="container">
      <div>
        <SectionTitle title="Часто задаваемые вопросы" />
        <SectionSubtitle subtitle="От вопросов к коду, от кода к ответам." />
        <div className="questions">
          {questions?.map((question) => (
            <QuestionBlock key={question.question} question={question} />
          ))}
        </div>
      </div>
      <div>
        <QuestionForm />
      </div>
    </div>
  </div>
);

export default Questions;
