import React, { useEffect, useState, Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Layout from "./components/UI/Layout/Layout";
import "./App.css";
import { BsFillCapslockFill } from "react-icons/bs";
import Main from "./containers/Main/Main";
import About from "./containers/About/About";
import Prices from "./containers/Prices/Prices";
import Projects from "./containers/Projects/Projects";
import Contacts from "./containers/Contacts/Contacts";

const App = () => {
  const location = useLocation();
  const [showToTopButton, setShowToTopButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowToTopButton(true);
    } else {
      setShowToTopButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout>
      <div>
        {showToTopButton && (
          <button
            className="to_top"
            onClick={scrollToTop}
          >
            <BsFillCapslockFill className="top_icon" />
          </button>)}
      </div>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={400}>
          <Routes location={location}>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<About />} />
            <Route path="/services_prices" element={<Prices />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route
              path="*"
              element={
                <div className="w-full h-[70vh] bg-black flex justify-center items-center">
                  <p className="text-white">Страница не найдена!</p>
                </div>
              }
            />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  );
};

export default App;
