// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/contacts_promo.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contacts_promo {\n    min-height: 420px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 10% / cover;\n}\n\n@media screen and (max-width: 1366px) {\n    .contacts_promo {\n        min-height: 292px;\n    }\n}\n\n@media screen and (max-width: 720px) {\n    .contacts_promo {\n        min-height: 248px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/containers/Contacts/Promo/Promo.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yEAAkF;AACtF;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".contacts_promo {\n    min-height: 420px;\n    background: url(\"../../../assets/images/contacts_promo.jpg\") no-repeat 10% / cover;\n}\n\n@media screen and (max-width: 1366px) {\n    .contacts_promo {\n        min-height: 292px;\n    }\n}\n\n@media screen and (max-width: 720px) {\n    .contacts_promo {\n        min-height: 248px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
