import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaSquareFacebook,
  FaSquareWhatsapp,
  FaTelegram,
} from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";

const ContactsBlock = () => (
  <div className="">
    <div className="lg:max-w-[900px] xl:max-w-[1100px] mt-[-10px] lg:mt-[-80px] mx-auto bg-[#2b2273] p-[30px] xl:p-[50px] lg:rounded-[20px]">
      <div className="grid grid-cols-1 lg:grid-cols-[3fr_4fr_4fr] gap-[20px]">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#fff]">Телефон:</p>
            <div className="flex flex-col gap-2">
              <NavLink target="_blank" className="font-normal text-[16px] xl:text-[18px] text-white hover:text-[#5699e8] duration-300" to={`tel:${+996709683738}`}>
                +996 709 683 738
              </NavLink>
              <NavLink className="font-normal text-[16px] xl:text-[18px] text-white hover:text-[#5699e8] duration-300" target="_blank" to={`tel:${+996557978715}`}>
                +996 557 978 715
              </NavLink>
            </div>
          </div>
          <div className="flex flex-col gap-1 ">
            <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#fff]">Мы в социальных сетях:</p>
            <div className="flex items-center gap-3">
              <NavLink
                to={"https://www.instagram.com/odigital.app/"}
                target="_blank"
              >
                <FaInstagramSquare className="w-8 h-8 fill-white hover:scale-110 duration-300" />
              </NavLink>
              <NavLink
                to={"https://www.facebook.com/oracledigital.kg/?locale=ru_RU"}
                target="_blank"
              >
                <FaSquareFacebook className="w-8 h-8 fill-white hover:scale-110 duration-300" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#fff]">Адрес:</p>
            <div className="flex flex-col gap-2">
              <NavLink
                className="font-normal text-[16px] xl:text-[18px] text-white hover:text-[#5699e8] duration-300"
                target="_blank"
                to="https://maps.app.goo.gl/zQaFsnVbgC5Eb3xR6"
              >
                Кыргызстан, г. Бишкек, ул. Ай 47/a
              </NavLink>
              <NavLink
                target="_blank"
                className="font-normal text-[16px] xl:text-[18px] text-white hover:text-[#5699e8] duration-300"
                to="https://maps.app.goo.gl/Xe9A1WutX8fvqDNBA"
              >
                Кыргызстан, г. Бишкек, ул. Манас 64/1
              </NavLink>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#fff]">Пишите нам в мессенджерах:</p>
            <div className="flex items-center gap-3">
              <NavLink
                to={"https://wa.me/+996557978715"}
                target="_blank"
              >
                <FaSquareWhatsapp className="w-8 h-8 fill-white hover:scale-110 duration-300" />
              </NavLink>
              <NavLink
                to={"https://t.me/+996557978715"}
                target="_blank"
              >
                <FaTelegram className="w-8 h-8 fill-white hover:scale-110 duration-300" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#fff]">E-mail:</p>
            <NavLink
              target="_blank"
              className="font-normal text-[16px] xl:text-[18px] text-white hover:text-[#5699e8] duration-300"
              to="mailto:oracledigital.llc@gmail.com"
            >
              oracledigital.llc@gmail.com"
            </NavLink>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#fff]">График работы:</p>
            <p className="font-normal text-[16px] xl:text-[18px] text-white">
              с понедельника по пятницу:10:00 - 19:00
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ContactsBlock;
