// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/icons/plus.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../assets/icons/minus.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".question {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n}\n\n.question span:last-child {\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 150%;\n    margin-left: 25px;\n}\n\n.plus::before {\n    content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.minus::before {\n    display: inline-block;\n    content: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    position: relative;\n    bottom: 5px;\n}\n\n.answer {\n    opacity: 0.7;\n    line-height: 150%;\n}\n\n@media screen and (max-width: 520px) {\n    .question span:last-child {\n        font-size: 16px;\n    }\n\n    .answer {\n        font-size: 14px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/containers/Main/Questions/QuestionBlock/QuestionBlock.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,gDAAiD;AACrD;;AAEA;IACI,qBAAqB;IACrB,gDAAkD;IAClD,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".question {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n}\n\n.question span:last-child {\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 150%;\n    margin-left: 25px;\n}\n\n.plus::before {\n    content: url(\"../../../../assets/icons/plus.png\");\n}\n\n.minus::before {\n    display: inline-block;\n    content: url(\"../../../../assets/icons/minus.jpg\");\n    position: relative;\n    bottom: 5px;\n}\n\n.answer {\n    opacity: 0.7;\n    line-height: 150%;\n}\n\n@media screen and (max-width: 520px) {\n    .question span:last-child {\n        font-size: 16px;\n    }\n\n    .answer {\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
