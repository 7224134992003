import React, { useEffect } from 'react'
import Promo from './Promo/Promo'
import Statics from '../../components/Statics/Statics'
import OurTarget from './OurTarget/OurTarget'
import Working from './Working/Working'
import Additional from './Additional/Additional'

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <Promo />
      <Statics />
      <OurTarget />
      <Working />
      <Additional />
    </>
  )
}

export default About
