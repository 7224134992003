import axios from "axios";
const bekentApi = "https://api.odigital.app/";

export const fetchProjects = async (selectedType, page) => {
  try {
    const response = await axios.get(`${bekentApi}api/project_list/`, {
      params: {
        type: selectedType,
        page: page,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching news:", error);
    throw new Error("Failed to fetch projects");
  }
};

export const fetchProjectsfilter = async (selectedType) => {
  try {
    const response = await axios.get(
      `${bekentApi}api/project_list/${selectedType}`
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching news:", error);
    throw new Error("Failed to fetch projects");
  }
};

export const fetchProjectsType = async () => {
  try {
    const response = await axios.get(`${bekentApi}api/project_type_list/`);
    return response.data;
  } catch (error) {
    console.log("Error fetching news:", error);
    throw new Error("Failed to fetch projects");
  }
};

export const fetchsServices = async () => {
  try {
    const response = await axios.get(`${bekentApi}api/services/`);
    return response.data;
  } catch (error) {
    console.log("Error fetching news:", error);
    throw new Error("Failed to fetch projects");
  }
};

export const fetchsReviews = async () => {
  try {
    const response = await axios.get(`${bekentApi}api/comment_list/`);
    return response.data;
  } catch (error) {
    console.log("Error fetching news:", error);
    throw new Error("Failed to fetch projects");
  }
};
export const fetchsPartners = async () => {
  try {
    const response = await axios.get(`${bekentApi}api/partner_list/`);
    return response.data;
  } catch (error) {
    console.log("Error fetching news:", error);
    throw new Error("Failed to fetch projects");
  }
};
