import React, { useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import SectionSubtitle from "../../../components/UI/SectionSubtitle/SectionSubtitle";
import Catalog from "../../../components/Catalog/Catalog";
import "./OurProjects.css";

import osh from "../../../assets/images/osh.jpg";
import opop from "../../../assets/images/opop.jpg";
import batbat from "../../../assets/images/batbat.jpg";
import CardProject from "../../../components/CardProject/CardProject";
import { useQuery } from "react-query";
import { fetchProjects, fetchProjectsType } from "../../../api/api";
import { Box, CircularProgress } from "@mui/material";

const OurProjects = () => {
  const [selectedType, setSelectedType] = useState(null);
  const { isLoading, error, data } = useQuery(
    ["projectList", selectedType],
    () => fetchProjects(selectedType)
  );
  const typeListQuery = useQuery("projectTypeList", fetchProjectsType);

  const handleTypeClick = (typeId) => {
    setSelectedType(typeId);
  };
  return (
    <div className="container">
      <div className="our_projects">
        <SectionTitle title="Наши выполненные проекты" />
        <div className="catalog">
          {typeListQuery?.data &&
            typeListQuery?.data?.map((type) => (
              <button
                key={type.id}
                className={`btn_catalog ${selectedType === type.id ? "catalog_active" : ""
                  }`}
                type="button"
                onClick={() => handleTypeClick(type.id)}
              >
                {type?.name}
              </button>
            ))}
        </div>
        {data?.length > 0 ? (
          <>
            {" "}
            <div className="projects">
              {isLoading ? (
                <div
                  style={{
                    margin: "100px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <CircularProgress />
                  </Box>
                </div>
              ) : (
                <>
                  {data?.slice(0, 3).map((item, i) => (
                    <CardProject key={i} item={item} />
                  ))}
                </>
              )}
            </div>
            <div className="projects_button">
              <Link to="/projects">Смотреть другие работы</Link>
            </div>
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h4>пусто</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default OurProjects;
