import React from "react";
import "./SecondBlock.css";

import monitoring from "../../../assets/icons/monitoring.png";
import adaptiveResponsive from "../../../assets/icons/adaptive_responsive_design_1_.png";
import analiz from "../../../assets/icons/analiz.png";
import optimiz from "../../../assets/icons/iconoir_graph-up.png";
import content from "../../../assets/icons/content.png";
import design from "../../../assets/icons/design.png";
import SecondBlockCard from "./SecondBlockCard/SecondBlockCard";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import SectionSubtitle from "../../../components/UI/SectionSubtitle/SectionSubtitle";

const data = [
  {
    title: "Система управления сайтом",
    image: monitoring,
    description:
      "Разработка удобной и заточенной под клиента системы управления сайтом",
  },
  {
    title: "Адаптивный сайт",
    image: adaptiveResponsive,
    description:
      "Создание адаптивного сайта под все существующие платформы и браузеры",
  },
  {
    title: "Анализ рынка и конкурентов",
    image: analiz,
    description:
      "Разработка концепции сайта, на основе анализа рынка, конкурентов и тенденций",
  },
  {
    title: "SEO оптимизация",
    image: optimiz,
    description:
      "Внутренняя SEO оптимизация сайта под поисковики Google и Яндекс",
  },
  {
    title: "Контент для сайта",
    image: content,
    description:
      "При необходимости написание вкусного, продающего контента для сайта",
  },
  {
    title: "Ux/Ui дизайн",
    image: design,
    description:
      "Разработка концепции сайта, на основе анализа рынка, конкурентов и тенденций",
  },
];

const SecondBlock = () => (
  <div className="container second_block_con" id="second">
    <div className="second_block_title_block">
      <SectionTitle title="Что входит в работу по разработке сайта" />
    </div>
    <div className="second_block_subtitle_block">
      <SectionSubtitle
        subtitle="Технологии, которые работают для вас: наши услуги - ваш путь к успешному проекту."
      />
    </div>
    <div className="grid xl:grid-cols-3 grid-cols-1 md:grid-cols-2 gap-[20px] ">
      {data.map((item) => (
        <SecondBlockCard key={item.title} card={item} />
      ))}
    </div>
  </div>
);

export default SecondBlock;
