import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import SectionSubtitle from "../../../../components/UI/SectionSubtitle/SectionSubtitle";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextMaskCustom from "../../../../components/TextMaskCustom/TextMaskCustom";
import axios from "axios";
import { useMutation, useQuery } from "react-query";

const QuestionForm = () => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const { mutate, isLoading, error, data, isSuccess, reset } = useMutation(
    async (formData) => {
      try {
        const response = await axios.post(
          "https://api.odigital.app/api/faq_create/",
          formData
        );
        setNotificationOpen(true); // Открываем уведомление после отправки
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );

  console.log(data);

  const [values, setValues] = useState({ phone: "", question: "" });

  const handleSubmit = (event) => {
    event.preventDefault();
    mutate(values);
    setValues({ phone: "", question: "" });
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };
  return (
    <Box component="form" mt="50px" onSubmit={handleSubmit}>
      <Typography
        variant="h3"
        fontWeight="600"
        textAlign="center"
        sx={{ fontSize: { xs: "30px", lg: "36px", xl: "40px" } }}
      >
        У вас остались вопросы?
      </Typography>
      <Grid
        container
        mx="auto"
        fontSize="20px"
        sx={{ width: { xs: "320px", md: "420px", lg: "560px" } }}
      >
        <Grid item xs={12} marginY="30px">
          <FormControl variant="standard" fullWidth required>
            <InputLabel htmlFor="formatted-text-mask-input">
              Ваш номер телефона
            </InputLabel>
            <Input
              value={values.phone}
              onChange={(event) =>
                setValues({ ...values, phone: event.target.value })
              }
              name="textmask"
              id="formatted-text-mask-input"
              inputComponent={TextMaskCustom}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} marginY="30px">
          <TextField
            label="Задавайте вопрос"
            variant="standard"
            fullWidth
            required
            value={values.question}
            onChange={(event) =>
              setValues({ ...values, question: event.target.value })
            }
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ opacity: isLoading ? 0.5 : 1 }}
            type="submit"
            fullWidth
            sx={{
              fontSize: "20px",
              py: "10px",
              color: "#5699E8",
              border: "1px solid #5699E8",
              borderRadius: "47px",
              textTransform: "none",
              ":hover": { background: "#5699E8", color: "#fff" },
            }}
          >
            {isLoading ? "Загрузка..." : "Задать вопрос"}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={5000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
          autoHideDuration={1200}
        >
          Ваш вопрос успешно отправлен!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QuestionForm;
