import React from 'react'
import './SecondBlockCard.css'

const SecondBlockCard = props => (
  <div className="second_block_card">
    <div className="second_block_card_img">
      <img src={props.card.image} alt={props.card.title} />
    </div>
    <h5>{props.card.title}</h5>
    <p>{props.card.description}</p>
  </div>
)

export default SecondBlockCard
