import React from "react";
import Card from "../../../components/Card/Card";
import "./PricesBlock.css";

import shop from "../../../assets/icons/shop.png";
import lending from "../../../assets/icons/lending.png";
import monitoring from "../../../assets/icons/monitoring.png";
import crm from "../../../assets/icons/crm.png";
import corporative from "../../../assets/icons/corpotive.png";
import mobile from "../../../assets/icons/mobile.png";
import { useQuery } from "react-query";
import { fetchsServices } from "../../../api/api";
import { Box, CircularProgress } from "@mui/material";


const PricesBlock = () => {
  const { data, isLoading, error } = useQuery("services", fetchsServices);
  return (
    <div className="container">
      {isLoading ? (
        <div
          style={{
            margin: "100px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[20px] mt-[40px] lg:mt-[60px] xl:mt-[80px]">
              {data?.map((item) => (
                <Card key={item.title} item={item} />
              ))}
            </div>
          ) : (
            <div>
              <h4 className="text-center my-[10%]">пусто</h4>
            </div>
          )}
        </>

      )}
    </div>
  );
};

export default PricesBlock;
