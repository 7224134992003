import React from "react";
import Map from "../Map/Map";
import { NavLink } from "react-router-dom";
import { FaInstagramSquare } from "react-icons/fa";
import Logo from '../Logo/Logo';

import {
  FaSquareFacebook,
  FaSquareWhatsapp,
  FaTelegram,
} from "react-icons/fa6";

const Footer = () => (
  <>
    <footer className="bg-black pb-[60px]  lg:pb-[100px] pt-[40px]  lg:pt-[60px] mt-[60px]">
      <div className="container flex flex-col gap-3">
        <div className="footer_top_logo">
          <Logo />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-[3fr_4fr_4fr]  xl:grid-cols-[3fr_4fr_5fr] gap-4">
          <div className="flex flex-col gap-3 ">
            <div className="flex flex-col gap-1">
              <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#5699e8]">Телефон:</p>
              <div className="flex flex-col gap-2">
                <NavLink className="font-normal text-[18px] lg:text-[16px] xl:text-[20px] text-white" target="_blank" to={`tel:${+996709683738}`}>
                  +996 709 683 738
                </NavLink>
                <NavLink className="font-normal text-[18px] lg:text-[16px] xl:text-[20px] text-white" target="_blank" to={`tel:${+996557978715}`}>
                  +996 557 978 715
                </NavLink>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#5699e8]">Пишите нам в мессенджерах</p>
                <div className="flex items-center gap-2">
                  <NavLink
                    to={"https://wa.me/+996557978715"}
                    target="_blank"
                  >
                    <FaSquareWhatsapp className="fill-white w-8 h-8 hover:scale-105 duration-300" />
                  </NavLink>
                  <NavLink
                    to={"https://t.me/+996557978715"}
                    target="_blank"
                  >
                    <FaTelegram className="fill-white w-8 h-8 hover:scale-105 duration-300" />
                  </NavLink>
                </div>
              </div>
              <div>
                <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#5699e8]">Мы в социальных сетях</p>
                <div className="flex items-center gap-2">
                  <NavLink
                    to={"https://www.instagram.com/odigital.app/"}
                    target="_blank"
                  >
                    <FaInstagramSquare className="fill-white w-8 h-8 hover:scale-105 duration-300" />
                  </NavLink>
                  <NavLink
                    to={
                      "https://www.facebook.com/oracledigital.kg/?locale=ru_RU"
                    }
                    target="_blank"
                  >
                    <FaSquareFacebook className="fill-white w-8 h-8 hover:scale-105 duration-300" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 ">
            <div className="flex flex-col gap-1">
              <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#5699e8]">Адрес:</p>
              <div className="flex flex-col gap-2">
                <NavLink
                  className="font-normal text-[18px] lg:text-[16px] xl:text-[20px] text-white"
                  target="_blank"
                  to="https://maps.app.goo.gl/zQaFsnVbgC5Eb3xR6"
                >
                  Кыргызстан, г. Бишкек, ул. Ай 47/a
                </NavLink>
                <NavLink className="font-normal text-[18px] lg:text-[16px] xl:text-[20px] text-white"
                  target="_blank"
                  to="https://maps.app.goo.gl/Xe9A1WutX8fvqDNBA"
                >
                  Кыргызстан, г. Бишкек, ул. Манас 64/1
                </NavLink>
              </div>
            </div>
            <div>
              <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#5699e8]">E-mail:</p>
              <NavLink className="font-normal text-[18px] lg:text-[16px] xl:text-[20px] text-white" target="_blank" to="mailto:oracledigital.llc@gmail.com">
                <span>oracledigital.llc@gmail.com</span>
              </NavLink>
            </div>
            <div>
              <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#5699e8]">График работы:</p>
              <span className="font-normal text-[18px] lg:text-[16px] xl:text-[20px] text-white">с понедельника по пятницу:10:00 - 19:00</span>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold text-[18px] lg:text-[16px] xl:text-[20px] text-[#5699e8]">Мы на карте</p>
            <div>
              <iframe className="w-full h-[200px] xl:h-[220px]" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2923.447871505634!2d74.5838431867181!3d42.88449652743074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389ec81c5f803c2d%3A0x49e7aaeeca5150b3!2zMSwgNjQg0YPQuy4g0JzQsNC90LDRgdCwLCDQkdC40YjQutC10Lo!5e0!3m2!1sru!2skg!4v1715774062341!5m2!1sru!2skg" width="600" height="auto" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
