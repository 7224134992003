import React from 'react'
import './Block.css'

const Block = ({ item, last }) => (
  <div className="blocks">
    {!last && <span className="block_line" />}
    <div className="block_number">
      <span>{item.number}</span>
    </div>
    <div className="block_description">
      <h6>{item.title}</h6>
      <p>{item.description}</p>
    </div>
  </div>
)

export default Block
