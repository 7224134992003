import React from "react";
import "./Promo.css";
import { memo } from "react";

const Promo = () => (
  <>
    <div className="w-full h-[150px] md:h-[110px] xl:h-[120px] bg-black" />
    <div className="about_promo">
      <div className="container about_promo_con">
        <div className="about_promo_min" />
        <div className="about_promo_description">
          <h4>О компании</h4>
          <p>
            Oracle Digital— это компания по разработке веб-сайтов и мобильных
            приложений, которая работает на рынке Кыргызстана с 2018 года. Наша
            компания является резидентом Парка Высоких Технологий КР и членом
            Блокчейн Департамента при Кыргызской Ассоциации Разработчиков
            Программного Обеспечения и Услуг (КАРПОУ), а также состоит в
            попечительском совете IT лицея 99 при Президенте КР.
          </p>
          <p>
            В апреле 2023 года на Кыргызстанском форуме информационных
            технологий мы стали победителем в номинации “Лучшая отечественная
            цифровая платформа”. На сегодняшний день наша компания сотрудничает
            со многими крупными организациями, государственными структурами,
            финансовыми организациями, а портфолио насчитывает более 80 успешно
            реализованных проектов
          </p>
        </div>
      </div>
    </div>
  </>
);

export default memo(Promo);
