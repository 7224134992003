import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import SectionSubtitle from "../../../components/UI/SectionSubtitle/SectionSubtitle";
import CardProject from "../../../components/CardProject/CardProject";
import "./ProjectsBlock.css";
import { useQuery } from "react-query";
import { fetchProjects, fetchProjectsType } from "../../../api/api";
import { Paginate } from "../../../components/Paginate/Paginate";

const ProjectsBlock = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, error, data, refetch } = useQuery(
    ["projectList", selectedType, currentPage],
    () => fetchProjects(selectedType, { page: currentPage })
  );

  const typeListQuery = useQuery("projectTypeList", fetchProjectsType);

  const handleTypeClick = (typeId) => {
    setSelectedType(typeId);
    setCurrentPage(1);
  };

  const newsPerPage = 6;

  const news = data || [];

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = news.slice(indexOfFirstNews, indexOfLastNews);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  useEffect(() => {
    refetch();
  }, [currentPage, selectedType, refetch]);

  return (
    <div className="container">
      <div className="projects_title">
        <SectionTitle title="Наши проекты" />
        <SectionSubtitle subtitle="Наши клиенты знают, что мы создаем шедевры, которые поражают воображение, качественно выделяя компанию среди конкурентов" />
      </div>
      <div className="catalog">
        <button
          className={`btn_catalog ${selectedType === null ? "catalog_active" : ""}`}
          type="button"
          onClick={() => handleTypeClick(null)}
        >
          Все
        </button>
        {typeListQuery?.data?.map((type) => (
          <button
            key={type.id}
            className={`btn_catalog ${selectedType === type.id ? "catalog_active" : ""
              }`}
            type="button"
            onClick={() => handleTypeClick(type.id)}
          >
            {type?.name}
          </button>
        ))}
      </div>
      <div className="projects_block">
        {isLoading ? (
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              style={{ margin: "100px 0px" }}
            >
              <CircularProgress />
            </Box>
          </div>
        ) : (
          <>
            {currentNews?.map((item, i) => (
              <CardProject key={`item.title${i}`} item={item} />
            ))}
          </>
        )}
      </div>
      <div className="pagination_block">
        <Paginate
          currentPage={currentPage}
          newsPerPage={newsPerPage}
          totalNews={news.length}
          paginate={paginate}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ProjectsBlock;
